<template>
  <div class="tw-hr">
    <span class="tw-hr__text"><slot></slot></span>
  </div>
</template>

<style lang="postcss" scoped>
.tw-hr {
  @apply flex items-center before:h-[1px] before:grow before:bg-neutral-200 before:content-[''] after:h-[1px] after:grow after:bg-neutral-200 after:content-[''];
  &__text {
    @apply shrink-0 px-[6px] text-12 text-neutral-500;
  }
}
</style>
